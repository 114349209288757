/* Template */

.invert {
    color: #fff;
    background-color: #111;
    img {
        filter: invert(1);
    }
    a {
        color:rgb(237, 110, 46);
    }
}

.halloween {
    color: #fff;
    background-color: #111;
    img {
        filter: invert(72%) sepia(35%) saturate(4853%) hue-rotate(359deg) brightness(103%) contrast(106%);
    }
    a {
        color: #FFA500;
    }
}

.site-grid { display: grid; min-height: 100vh; margin: 0 1rem; grid-template-rows: 20vh auto 5rem; }

@media (min-width: $tablet) { .site-grid { grid-template-columns: [gutter-left] 10% [content-left] 3fr [content-right] 1fr [gutter-right] 10%; } }

header { justify-self: end; }

@media (min-width: $tablet) { header { grid-column: content-right; } }

.logo { width: 10rem;}

@media (min-width: $tablet) { .logo { width: 13rem; } }

@media (min-width: $tablet) { .content { grid-column: content-left; } }

.reserve { text-transform: uppercase; font-weight: bolder; text-shadow: 1px 1px 2px #fff;}

.registration { margin-top: 5rem; font-size: 1.1rem; }

.reserve::after { content: " \2192"; }

@media (min-width: $tablet) { .registration { width: 50%; font-size: 1.1rem; } }

form { display: flex; flex-direction: column; }

input, textarea, button { font-family: inherit; font-size: inherit; border-style: none; }

input, textarea { margin-bottom: 1.5rem; padding: 0.75rem; }

textarea { height: 7rem; resize: none; }

button { color:#fff; padding:0.5rem; background-color: #926A68; }