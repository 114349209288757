*, *::before, *::after { box-sizing: border-box; }

body, figure, blockquote, dl, dd { margin: 0; }

html { scroll-behavior: smooth; }

body { line-height: 1.5; }

ul, ol { list-style: none; padding: 0 }

a:not([class]) { text-decoration-skip-ink: auto; }

img, picture { max-width: 100%; display: block; }

body { font-family: 'IBM Plex Sans', sans-serif; color: #000; background-color: #D2E4E5; font-size: 1.5rem; }

a:visited, a:hover, a:active, a:link { color: #000; }

@media (min-width: 46.8em) { body { font-size: 1.75rem; } }