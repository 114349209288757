@charset "utf-8";

$base-font-size: 1rem;

$background-color: #f9f9f9;
$highlight-color: #4C5F6B;
$alt-highlight: #83A0A0;

$tablet: 46.8em;
$desktop: 85.375em;

// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "base",
    "layout";
